@font-face {
  font-family: "Armag Fury";
  src: url("Armag-Fury.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Armag Fury";
  src: url("Armag-FuryItalic.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
}

html {
  min-height: 100%;
}

body {
  background: black;
  background-image: url("april-bg-large.jpg?width=1920");
  background-image: url("april-bg-large.jpg?as=webp&width=1920");
  background-size: cover;
  background-position: 50% 50%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100%;
  font-family: "Armag Fury";
  color: rgb(255, 254, 243);
  line-height: 1.1;
  letter-spacing: -0.05em;
  margin: 0;
}

* {
  box-sizing: border-box;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
}

body.privacy-policy .wrapper main {
  background: rgba(255, 255, 255, 0.9);
  color: black;
  font-family: "Helvetica Now", "Helvetica Neue", "Helvetica", system-ui,
    sans-serif;
  letter-spacing: 0;
  line-height: 1.4;
  max-width: 94ch;
  padding: 2rem;
  border-radius: 0.5rem;
  border: 1px solid black;
  margin-block-end: 1rem;
}

table th {
  text-align: left;
  font-weight: normal;
  margin-inline-end: 1rem;
  display: inline-block;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: 0.5rem;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1 {
  margin-block-start: 0;
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
  text-decoration: underline;
}
h2,
p,
ul {
  margin-block-start: 0.5rem;
  margin-block-end: 0.5rem;
}

footer {
  color: white;
  font-size: 1.1rem;
}

@media screen and (min-width: 700px) {
  footer {
    font-size: 1.4rem;
  }
}

footer a {
  text-decoration: none;
  color: white;
}

footer a:hover {
  text-decoration: underline;
}

footer a:visited {
  color: white;
}

.logo {
  font-size: 4rem;
  text-transform: uppercase;
  max-width: 600px;
  width: 100%;
  user-select: none;
}

.large-copy {
  font-size: 1.4rem;
  margin-block-end: 1em;
}

.small-print {
  text-align: center;
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

.large-copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 54ch;
}

.large-copy p {
  text-align: center;
  /* color: #ffadff17; */
  /* color: white; */
  font-style: italic;
  /* -webkit-text-stroke: 1.25px rgb(237, 110, 216); */
  /* text-shadow: 0px 0px 1px #d462c133; */
  /* -webkit-text-stroke: 2px pink; */
  /* filter: drop-shadow(0px 0px 4px #dc0fdcc2); */
  /* filter: drop-shadow(0px 0px 4px #dc0fdcc2) drop-shadow(0px 0px 10px #ffadffc2); */
  /* animation: 4s linear flicker infinite; */
}

.large-copy p:first-child {
  font-style: normal;
}

/* .large-copy a {
  color: #a3f979;
  border-color: #a3f979;
}

.large-copy a:hover {
  background-color: #a3f979;
} */

picture img {
  max-width: 100%;
  height: auto;
}

.line-up {
  --letter-spacing: -0.07em;
  font-size: 1.15rem;
  max-width: 54ch;
  text-align: center;
  animation: 4s linear flicker;
  color: #ffbffd;
}

.line-up span {
  filter: drop-shadow(0.01em 0.01em 0em #ec3686)
    drop-shadow(0px 0px 0.05em #ec3686) drop-shadow(0px 0px 0.3em #ffadffc2);
}

sup.djs span {
  filter: initial;
}

.large-copy p {
  --letter-spacing: -0.07em;
  max-width: 54ch;
  text-align: center;
}

.line-up .tier-1 {
  letter-spacing: var(--letter-spacing);
  font-size: 2em;
}

.line-up .tier-1 .line-1 {
  font-size: 1.2em;
  letter-spacing: var(--letter-spacing);
  font-style: italic;
  line-height: 0.8;
  display: inline-block;
}

.line-up .tier-1 .line-2 span {
  margin-inline-start: 1em;
}

.line-up .tier-2 {
  font-size: 1.4em;
  letter-spacing: var(--letter-spacing);
  position: relative;
  display: inline-block;
}

.a-z {
  position: absolute;
  transform: translate(-80%, -80%) rotate(-3deg);
  font-size: 0.5em;
  font-style: italic;
  top: 0;
  left: 0;
}

.line-up .tier-3,
.line-up .tier-4 {
  font-size: 1.05em;
  letter-spacing: var(--letter-spacing);
}

.line-up span:after {
  content: " • ";
}

.line-up span:last-of-type:after {
  content: "";
}

p,
span {
  letter-spacing: var(--letter-spacing);
}

.odd-even span:nth-of-type(2n - 1) {
  font-style: italic;
}

.even-odd span:nth-of-type(2n) {
  font-style: italic;
}

.form {
  margin-block: 2rem 1.5rem;
}

.form {
  position: relative;
}

#email {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

sup.djs {
  display: inline-flex;
  flex-direction: column;
  font-size: 0.5em;
}

sup.djs span:after {
  content: "";
}

input,
.btn {
  border: 1px solid white;
  /* border: 0; */
  /* border-bottom: 1px solid white; */
  background-color: transparent;
  color: white;
  font-family: "Armag Fury";
  font-size: inherit;
  /* line-height: 1; */
  letter-spacing: -0.03em;
  padding: 0.2rem;
  margin-block-end: 0.5rem;
  /* transition: border-bottom 300ms; */
}

input::placeholder {
  text-align: center;
}

input:focus,
input:focus-within,
input:focus-visible {
  outline: none;
  /* outline: 1px solid white; */
  outline-offset: 2px;
  /* border-bottom: 2px solid white; */
}

input[type="submit"],
.btn {
  cursor: pointer;
  border: 1px solid white;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
  margin-inline-start: 0rem;
}

input[type="submit"]:hover,
.btn:hover {
  background-color: white;
  color: black;
  /* mix-blend-mode: screen; */
}

a.btn {
  font-size: 2rem;
  margin-block: 1rem;
  text-decoration: none;
  /* line-height: 1; */
  padding: 0.3rem 1rem 0.4rem 1rem;
  text-transform: uppercase;
}

input::placeholder {
  opacity: 1;
}

input::-webkit-input-placeholder {
  opacity: 1;
}

input::-moz-placeholder {
  opacity: 1;
}

@media screen and (min-width: 380px) {
  .line-up .tier-2 {
    font-size: 1.6em;
  }

  .line-up .tier-3,
  .line-up .tier-4 {
    font-size: 1.1em;
    letter-spacing: var(--letter-spacing);
  }
}

@media screen and (min-width: 600px) {
  .logo {
    font-size: 7rem;
    text-transform: uppercase;
  }

  .large-copy {
    font-size: 1.8rem;
  }

  #email {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  input[type="submit"] {
    margin-inline-start: 1rem;
  }

  input {
    border: 0;
    border-bottom: 1px solid white;
  }

  input::placeholder {
    text-align: left;
  }

  .line-up {
    font-size: 1.8rem;
  }

  .line-up .tier-1 {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 1000px) {
  .line-up {
    font-size: 2.6rem;
  }

  .line-up .tier-1 {
    font-size: 2em;
  }

  .logo {
    font-size: 7rem;
    text-transform: uppercase;
  }

  .large-copy {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .logo {
    font-size: 7rem;
    text-transform: uppercase;
  }
}

main.information {
  max-width: 64ch;
  font-size: 1.4rem;
  line-height: 1.3;
  justify-content: flex-start;
  align-items: flex-start;
  margin-block: 3rem;
}

main.information h1 {
  font-size: 2.4rem;
}

main.information h2 {
  /* text-decoration: none; */
  text-transform: uppercase;
}

main.information a {
  color: white;
}

footer {
  line-height: 1.5;
}
