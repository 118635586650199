@font-face {
  font-family: Armag Fury;
  src: url("Armag-Fury.849ad2ab.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Armag Fury;
  src: url("Armag-FuryItalic.e1fc1ae5.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
}

html {
  min-height: 100%;
}

body {
  color: #fffef3;
  letter-spacing: -.05em;
  background: #000 url("april-bg-large.ab582ee3.webp") 50% / cover no-repeat fixed;
  min-height: 100%;
  margin: 0;
  font-family: Armag Fury;
  line-height: 1.1;
}

* {
  box-sizing: border-box;
}

main {
  filter: drop-shadow(0 0 20px #00000080);
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  display: flex;
}

body.privacy-policy .wrapper main {
  color: #000;
  letter-spacing: 0;
  background: #ffffffe6;
  border: 1px solid #000;
  border-radius: .5rem;
  max-width: 94ch;
  margin-block-end: 1rem;
  padding: 2rem;
  font-family: Helvetica Now, Helvetica Neue, Helvetica, system-ui, sans-serif;
  line-height: 1.4;
}

table th {
  text-align: left;
  margin-inline-end: 1rem;
  font-weight: normal;
  display: inline-block;
}

.wrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  padding: .5rem;
  display: flex;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  margin-block-start: 0;
  font-size: 1.5rem;
}

h2 {
  font-size: 1.3rem;
  text-decoration: underline;
}

h2, p, ul {
  margin-block: .5rem;
}

footer {
  color: #fff;
  font-size: 1.1rem;
}

@media screen and (min-width: 700px) {
  footer {
    font-size: 1.4rem;
  }
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer a:visited {
  color: #fff;
}

.logo {
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  max-width: 600px;
  font-size: 4rem;
}

.large-copy {
  margin-block-end: 1em;
  font-size: 1.4rem;
}

.small-print {
  text-align: center;
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: .9;
  }

  6% {
    opacity: .85;
  }

  8% {
    opacity: .95;
  }

  10% {
    opacity: .9;
  }

  11% {
    opacity: .922;
  }

  12% {
    opacity: .9;
  }

  14% {
    opacity: .95;
  }

  16% {
    opacity: .98;
  }

  17% {
    opacity: .9;
  }

  19% {
    opacity: .93;
  }

  20% {
    opacity: .99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: .94;
  }

  28% {
    opacity: .98;
  }

  37% {
    opacity: .93;
  }

  38% {
    opacity: .5;
  }

  39% {
    opacity: .96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: .97;
  }

  46% {
    opacity: .94;
  }

  56% {
    opacity: .9;
  }

  58% {
    opacity: .9;
  }

  60% {
    opacity: .99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: .9;
  }

  72% {
    opacity: .95;
  }

  93% {
    opacity: .93;
  }

  95% {
    opacity: .95;
  }

  97% {
    opacity: .93;
  }

  to {
    opacity: 1;
  }
}

.large-copy {
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 54ch;
  display: flex;
}

.large-copy p {
  text-align: center;
  font-style: italic;
}

.large-copy p:first-child {
  font-style: normal;
}

picture img {
  max-width: 100%;
  height: auto;
}

.line-up {
  --letter-spacing: -.07em;
  text-align: center;
  color: #ffbffd;
  max-width: 54ch;
  font-size: 1.15rem;
  animation: 4s linear flicker;
}

.line-up span {
  filter: drop-shadow(.01em .01em 0 #ec3686) drop-shadow(0 0 .05em #ec3686) drop-shadow(0 0 .3em #ffadffc2);
}

sup.djs span {
  filter: initial;
}

.large-copy p {
  --letter-spacing: -.07em;
  text-align: center;
  max-width: 54ch;
}

.line-up .tier-1 {
  letter-spacing: var(--letter-spacing);
  font-size: 2em;
}

.line-up .tier-1 .line-1 {
  letter-spacing: var(--letter-spacing);
  font-size: 1.2em;
  font-style: italic;
  line-height: .8;
  display: inline-block;
}

.line-up .tier-1 .line-2 span {
  margin-inline-start: 1em;
}

.line-up .tier-2 {
  letter-spacing: var(--letter-spacing);
  font-size: 1.4em;
  display: inline-block;
  position: relative;
}

.a-z {
  font-size: .5em;
  font-style: italic;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-80%, -80%)rotate(-3deg);
}

.line-up .tier-3, .line-up .tier-4 {
  letter-spacing: var(--letter-spacing);
  font-size: 1.05em;
}

.line-up span:after {
  content: " • ";
}

.line-up span:last-of-type:after {
  content: "";
}

p, span {
  letter-spacing: var(--letter-spacing);
}

.odd-even span:nth-of-type(2n-1), .even-odd span:nth-of-type(2n) {
  font-style: italic;
}

.form {
  margin-block: 2rem 1.5rem;
  position: relative;
}

#email {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

sup.djs {
  flex-direction: column;
  font-size: .5em;
  display: inline-flex;
}

sup.djs span:after {
  content: "";
}

input, .btn {
  color: #fff;
  font-family: Armag Fury;
  font-size: inherit;
  letter-spacing: -.03em;
  background-color: #0000;
  border: 1px solid #fff;
  margin-block-end: .5rem;
  padding: .2rem;
}

input::placeholder {
  text-align: center;
}

input:focus, input:focus-within, input:focus-visible {
  outline-offset: 2px;
  outline: none;
}

input[type="submit"], .btn {
  cursor: pointer;
  border: 1px solid #fff;
  margin-inline-start: 0;
  padding: .2rem .5rem .1rem;
}

input[type="submit"]:hover, .btn:hover {
  color: #000;
  background-color: #fff;
}

a.btn {
  text-transform: uppercase;
  margin-block: 1rem;
  padding: .3rem 1rem .4rem;
  font-size: 2rem;
  text-decoration: none;
}

input::placeholder {
  opacity: 1;
}

input::placeholder {
  opacity: 1;
}

input::placeholder {
  opacity: 1;
}

@media screen and (min-width: 380px) {
  .line-up .tier-2 {
    font-size: 1.6em;
  }

  .line-up .tier-3, .line-up .tier-4 {
    letter-spacing: var(--letter-spacing);
    font-size: 1.1em;
  }
}

@media screen and (min-width: 600px) {
  .logo {
    text-transform: uppercase;
    font-size: 7rem;
  }

  .large-copy {
    font-size: 1.8rem;
  }

  #email {
    flex-direction: row;
    justify-content: center;
    display: flex;
  }

  input[type="submit"] {
    margin-inline-start: 1rem;
  }

  input {
    border: 0;
    border-bottom: 1px solid #fff;
  }

  input::placeholder {
    text-align: left;
  }

  .line-up {
    font-size: 1.8rem;
  }

  .line-up .tier-1 {
    font-size: 1.6em;
  }
}

@media screen and (min-width: 1000px) {
  .line-up {
    font-size: 2.6rem;
  }

  .line-up .tier-1 {
    font-size: 2em;
  }

  .logo {
    text-transform: uppercase;
    font-size: 7rem;
  }

  .large-copy {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .logo {
    text-transform: uppercase;
    font-size: 7rem;
  }
}

main.information {
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 64ch;
  margin-block: 3rem;
  font-size: 1.4rem;
  line-height: 1.3;
}

main.information h1 {
  font-size: 2.4rem;
}

main.information h2 {
  text-transform: uppercase;
}

main.information a {
  color: #fff;
}

footer {
  line-height: 1.5;
}

/*# sourceMappingURL=index.34c1809c.css.map */
